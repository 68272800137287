<template>
  <v-card>
    <!-- <v-card class="mt-5"> -->
    <!-- <v-sheet
      class="v-sheet--offset mx-auto rounded-lg"
      color="accent"
      elevation="6"
      max-width="calc(100% - 32px)"
      dark
    >
      <v-card-title class="font-weight-light" style="word-break: break-word">
        {{ topConfig.title }}
      </v-card-title>
    </v-sheet> -->

    <v-card-title class="subtitle-1 primary--text" style="word-break: normal">
      {{ topConfig.title }}
    </v-card-title>

    <v-card-text v-if="kpiLoader" class="text-center">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-card-text>

    <v-card-text v-else-if="topData.length == 0" class="text-center">
      <p class="font-weight-light">{{ topConfig.noDataText }}</p>
    </v-card-text>

    <v-card-text v-else class="mt-n4 pt-0">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th v-for="(column, i) in topConfig.columns" :key="i">
                <div v-if="i == 0">{{ column }}</div>
                <div v-else class="text-center">{{ column }}</div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, i) in topData" :key="i">
              <td v-for="(data, j) in row" :key="j">
                <div v-if="j == 'key'">
                  <v-chip outlined>
                    <v-icon v-if="topConfig.type == 'cashier'" left
                      >mdi-cash-register</v-icon
                    >
                    <v-icon v-else-if="topConfig.type == 'card'" left
                      >mdi-card-account-details-star</v-icon
                    >
                    {{ data }}
                  </v-chip>
                </div>
                <div v-else-if="j == 'triggered_financial_risk'" class="text-center">
                  {{ data | formatCurrency }} €
                </div>
                <div v-else class="text-center">
                  {{ data }}
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    topConfig: {
      type: Object,
      required: true,
    },
    topData: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState({
      kpiLoader: (state) => state.kpi.kpiLoader,
    }),
  },
};
</script>

<style scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}

tbody tr:hover {
  background-color: transparent !important;
}
</style>
